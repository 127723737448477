<!-- 旗舰话费 -->
<template>
  <div>

    <!-- 倒计时 -->
    <TimerComp :order-info="orderInfo" :pay-time-info="payTimeInfo" />

    <!--唤醒按钮-->
    <div class="pay-alip">
      <!--  -->
      <div>
        <a class="btn btn-alip" style="width: 70%;" @click="devPayd">点此测试已支付</a>
      </div>

    </div>

    <!-- 警告事项 -->
    <div class="pannel-simp attention">
      <p class="">注意：<span style="color: red;">本通道为系统测试订单，不纳入记账体系</span>；点击按钮后，可测试回调</p>
    </div>

  </div>
</template>

<script>
import * as apis from "@/apis/apis";
//
import TimerComp from "@/views/comp/timer_comp";
//
export default {
  components: {
    TimerComp,
  },
  props: {
    orderInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    payTimeInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {},
  created() {},
  methods: {
    // 测试已支付
    devPayd() {
      apis
        .devPayd({
          id: this.orderInfo.orderId,
        })
        .then((res) => {
          console.log(res);
          location.reload();
        })
        .catch((err) => {
          console.log("发生错误");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss">
</style>

<style lang="scss" scoped>
</style>
