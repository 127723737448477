import request from "@/utils/request";


// 拉单
export function createPayInfo(data) {
    return request({
        url: "/cashier/createPayInfo",
        method: "post",
        data,
    });
}

// 获取支付信息
export function getPayInfo(data) {
    return request({
        url: "/cashier/getPayInfo",
        method: "post",
        data,
    });
}

// 提交停留时间
export function subTimes(data) {
    return request({
        url: "/cashier/time_sub",
        method: "post",
        data,
    });
}

// 提交拷贝时间
export function subCopy(data) {
    return request({
        url: "/cashier/copy_info",
        method: "post",
        data,
    });
}

// 提交拷贝出错
export function subCopyErr(data) {
    return request({
        url: "/cashier/copy_error",
        method: "post",
        data,
    });
}

// 沃达丰-提交卡号
export function subVdfPayCard(data) {
    return request({
        url: "/cashier/vdf_pay_card",
        method: "post",
        data,
    });
}

// 测试已支付
export function devPayd(data) {
    return request({
        url: "/cashier/dev_payd",
        method: "post",
        data,
    });
}

